import React from "react";
import { Link } from "react-router-dom";
import "./PlannerScreen.css";

const PlannerScreen = () => {
  return (
    <div className="planner-screen grid-background">
      <div className="arrow-container">
        <img
          src={`${process.env.PUBLIC_URL}/Go.svg`}
          alt="Curved Arrow with GO"
          className="curved-arrow"
        />
        <Link to="/some-path" className="clickable-text">
          Next
        </Link>
      </div>
      <div className="bg-pink-200 p-8 rounded-lg shadow-lg max-w-xs text-center">
        <h2 className="text-xl font-semibold text-pink-700">2024 PLANNER</h2>
        <h3 className="text-md font-semibold text-pink-700 mt-2">
          THIS IS YOUR SPACE FOR THE TAKING
        </h3>
        <p className="mt-4 text-gray-800">
          Create a mindmap of your dreams, goals, and ideas. Somewhere to plan
          without limits, plot your personal growth and celebrate small or big
          victories.
        </p>
      </div>
    </div>
  );
};

export default PlannerScreen;
