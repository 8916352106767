import React from "react";
import { Link } from "react-router-dom";

const Welcome = () => {
  return (
    <div
      className="flex items-center justify-center h-screen flex-col"
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${process.env.PUBLIC_URL}/Planner.svg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <h1 className="text-9xl" style={{ color: "#00796b" }}>
        Planner
      </h1>
      <p className="mt-8 text-xl text-center max-w-4xl text-gray-700">
        Welcome to Planner, your personal digital diary where you can capture
        your thoughts, memories, and experiences. Whether it's your day, month,
        or year, Planner helps you reflect, plan, and keep track of your
        journey. Start writing and create your own story, one entry at a time.
      </p>
      <Link to="/planner">
        <button className="mt-8 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg">
          Get Started
        </button>
      </Link>
    </div>
  );
};

export default Welcome;
