import Welcome from "./Components/Welcome/Welcome";
import PlannerScreen from "./Components/Welcome/PlannerScreen";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Welcome />} />
      <Route path="planner" element={<PlannerScreen />} />
    </Routes>
  );
}

export default App;
